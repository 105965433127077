import axios from 'axios'
import proxy from '../../utils/proxy'
import finalizeProducts from '../../utils/finalizeProducts'
import {
  PRODUCT_SELECT,
  PRODUCT_NOT_FOUND,
  PRODUCT_CATEGORY,
  PRODUCT_RESET,
  PRODUCT_ERROR,
  PRODUCT_SEARCH,
  PRODUCT_SIMILAR,
  PRODUCT_FAMILY,
  PRODUCT_GET_DISPLAY,
  PRODUCT_UUTUUDET,
  PRODUCT_GET_MOST_VIEWED,
  PRODUCT_OUTLET,
} from './types'

// Hae yhden tuotteen tiedot id:n perusteella
export const actionProductSelect = (code) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const body = JSON.stringify({ code })

  try {
    const res = await axios.post(`${proxy}/api/public/get-product`, body, config)

    if (res.status === 200) {
      dispatch({
        type: PRODUCT_SELECT,
        payload: res.data,
      })

      let recentlyViewed = localStorage.getItem('recently-viewed')
      let finalArray = []
      if (!recentlyViewed) {
        recentlyViewed = []
        recentlyViewed.push(code)
        localStorage.setItem('recently-viewed', JSON.stringify(recentlyViewed))
      } else {
        let modified = JSON.parse(recentlyViewed)
        if (!modified.includes(code)) {
          modified.unshift(code)
          finalArray = modified.slice(0, 5)
        } else {
          const n = modified.indexOf(code)
          modified.splice(n, 1)
          modified.unshift(code)
          finalArray = modified.slice(0, 5)
        }
        localStorage.setItem('recently-viewed', JSON.stringify(finalArray))
      }
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: PRODUCT_NOT_FOUND,
    })
  }
}

// Päivitä tuotteen katsonta laskuri
export const actionProductViewCountPlusOne = (code) => async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const body = JSON.stringify({ product: code })

  try {
    await axios.post(`${proxy}/api/public/update-stats-views`, body, config)
  } catch (err) {
    console.error(err)
  }
}

export const actionProductKatsotuimmat =
  ({ customList, katsotuimmat }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    try {
      if (customList) {
        const body = JSON.stringify({ array: katsotuimmat })
        const res = await axios.post(`${proxy}/api/public/get-data-for-buttons`, body, config)

        if (res.status === 200) {
          const fullData = res.data

          function sortFunc(a, b) {
            return katsotuimmat.indexOf(a.tuoteKoodi) - katsotuimmat.indexOf(b.tuoteKoodi)
          }

          fullData.sort(sortFunc)

          dispatch({
            type: PRODUCT_GET_MOST_VIEWED,
            payload: res.data,
          })
        }
      } else {
        const res = await axios.get(`${proxy}/api/public/get-most-viewed`, config)

        if (res.status === 200) {
          const body2 = JSON.stringify({ array: res.data })
          const res2 = await axios.post(`${proxy}/api/public/get-data-for-buttons`, body2, config)

          if (res2.status === 200) {
            const fullData = res2.data

            function sortFunc(a, b) {
              return res.data.indexOf(a.tuoteKoodi) - res.data.indexOf(b.tuoteKoodi)
            }

            fullData.sort(sortFunc)

            dispatch({
              type: PRODUCT_GET_MOST_VIEWED,
              payload: res2.data,
            })
          }
        }
      }
    } catch (err) {
      console.error(err)

      dispatch({
        type: PRODUCT_ERROR,
      })
    }
  }

export const actionProductUutuudet = (array) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const codeArray = array.map((el) => el.tuoteKoodi)

  const body = JSON.stringify({ codeArray })

  try {
    const res = await axios.post(`${proxy}/api/public/get-products`, body, config)

    if (res.status === 200) {
      let payload = array

      function getFields(key, i) {
        let correctObject = res.data.filter((el) => el.tuoteKoodi === key)
        payload[i].tuoteNimi = correctObject[0].tuoteNimi
        payload[i].brandi = correctObject[0].brandi
        payload[i].variaatiot = correctObject[0].variaatiot
      }

      await payload.forEach((el, i) => getFields(el.tuoteKoodi, i))

      dispatch({
        type: PRODUCT_UUTUUDET,
        payload: payload,
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: PRODUCT_ERROR,
    })
  }
}

export const actionProductGetSimilar = (array) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const body = JSON.stringify({ array: array })

  try {
    const res = await axios.post(`${proxy}/api/public/get-data-for-buttons`, body, config)

    if (res.status === 200) {
      dispatch({
        type: PRODUCT_SIMILAR,
        payload: finalizeProducts(res.data),
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: PRODUCT_ERROR,
    })
  }
}

export const actionProductGetFamily = (array) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const body = JSON.stringify({ array: array })

  try {
    const res = await axios.post(`${proxy}/api/public/get-data-for-buttons`, body, config)

    if (res.status === 200) {
      dispatch({
        type: PRODUCT_FAMILY,
        payload: finalizeProducts(res.data),
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: PRODUCT_ERROR,
    })
  }
}

export const actionProductCategory = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${proxy}/api/public/get-category?id=${id}`)

    if (res.status === 200) {
      dispatch({
        type: PRODUCT_CATEGORY,
        payload: finalizeProducts(res.data),
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: PRODUCT_ERROR,
    })
  }
}

// Etsi tuotteita hakusanalla
export const actionProductSearch = (keyword) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  let decoded = decodeURIComponent(keyword).replace(/~~pct~~/g, '%')

  const body = JSON.stringify({ keyword: decoded })

  try {
    const res = await axios.post(`${proxy}/api/public/search-products`, body, config)

    if (res.status === 200) {
      const data = res.data.map((el) => ({
        ...el,
        score: parseFloat(el.score).toFixed(10),
      }))

      dispatch({
        type: PRODUCT_SEARCH,
        payload: { data: finalizeProducts(data), keyword: keyword },
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: PRODUCT_ERROR,
    })
  }
}

// Hae outlet tuotteet
export const actionProductGetOutlet = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    const res = await axios.get(`${proxy}/api/public/get-outlet`, config)

    if (res.status === 200) {
      const finalizedProducts = finalizeProducts(res.data).filter((el) => el.isOutlet)
      for (let prod of finalizedProducts) {
        prod.variaatiot = prod?.variaatiot?.filter((v) => v.saldo1 && v.saldo1 > 0)
      }
      dispatch({
        type: PRODUCT_OUTLET,
        payload: finalizedProducts.filter((prod) => prod.variaatiot.length),
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: PRODUCT_ERROR,
    })
  }
}

export const actionProductGetDisplay = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    const res = await axios.get(`${proxy}/api/public/get-display`, config)

    if (res.status === 200) {
      const fetchProducts = (el) => async () => {
        await axios.post(
          `${proxy}/api/public/get-product`,
          { code: el.tuoteKoodi },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
      }

      res.data.uutuudet.forEach(() => fetchProducts())

      dispatch({
        type: PRODUCT_GET_DISPLAY,
        payload: res.data,
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: PRODUCT_ERROR,
    })
  }
}

export const actionProductReset = () => (dispatch) => {
  dispatch({
    type: PRODUCT_RESET,
  })
}
