// React & Routing
import React from 'react'
import { Link } from 'react-router-dom'

// Components
import MultiCarouselBrands from '../MultiCarousel/MultiCarouselBrands'

// Bootstrap
import Col from 'react-bootstrap/Col'

// Styling
import './Footer.scss'

const Footer = () => {
  return (
    <div className='Footer'>
      <MultiCarouselBrands />
      <div className='footerContainer'>
        <Col>
          <div>
            <h6>Tietoa sivustosta</h6>
            <Link to='/info'>
              <p>Keitä olemme</p>
            </Link>
            <Link to='/terms'>
              <p>Tilausehdot</p>
            </Link>
            <Link to='/data-protection'>
              <p>Tietosuojakäytännöt</p>
            </Link>
          </div>
        </Col>
        <Col>
          <div>
            <h6>Tutustu valikoimaan</h6>
            <Link to='/campaigns'>
              <p>Kampanjat</p>
            </Link>
            <Link to='/collections'>
              <p>Kuvastot</p>
            </Link>
            <Link to='/brands'>
              <p>Tuotemerkit</p>
            </Link>
          </div>
        </Col>
        <Col>
          <div>
            <h6>Asiakaspalvelu</h6>
            <Link to='/contact-us'>
              <p>Ota yhteyttä</p>
            </Link>
          </div>
        </Col>
      </div>
      <p>© Desperado Clothing Oy 2024</p>
    </div>
  )
}

export default Footer
