import axios from 'axios'
import setAuthToken from '../../utils/setAuthToken'
import finalizeProducts from '../../utils/finalizeProducts'
import { actionAlert } from '../../redux/actions/action.alert'
import {
  LOAD_USER,
  RELOAD_USER,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SUBMIT,
  LOGOUT,
  CHANGE_PASSWORD,
  RESET_PW_STATE,
  RECENTS_GET,
  RECENTS_ERROR,
  FAVORITES_GET,
  FAVORITES_ADD,
  FAVORITES_REMOVE,
  FAVORITES_ERROR,
  ADDRESSLIST_RESET,
  ADDRESSLIST_ADD,
  ADDRESSLIST_REMOVE,
  ADDRESSLIST_ERROR,
  PW_RESETCODE_VALIDITY,
  CONSUME_PW_RESET,
  RESET_USER_RELOAD,
} from './types'
import proxy from '../../utils/proxy'

// Lähetä salasanan nollaus linkki käyttäjän sähköpostiin, voimassa 24h
export const actionInitPwReset = (email) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const body = JSON.stringify({ email })

  try {
    const res = await axios.post(`${proxy}/api/public/reset-password`, body, config)

    if (res.status === 200) {
      alert('Salasanan nollaus linkki on lähetetty sähköpostiisi.')
      window.location = window.location.origin
    }
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(actionAlert(error.msg, 'danger')))
    }
  }
}

// Tarkista onko salasanan nollaus linkki vielä voimassa
export const actionCheckResetCodeValidity = (resetCode) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const body = JSON.stringify({ resetCode })

  try {
    const res = await axios.put(`${proxy}/api/public/reset-password`, body, config)

    if (res.status === 200) {
      dispatch({
        type: PW_RESETCODE_VALIDITY,
        payload: true,
      })
    } else {
      dispatch({
        type: PW_RESETCODE_VALIDITY,
        payload: false,
      })
    }
  } catch (err) {
    dispatch({
      type: PW_RESETCODE_VALIDITY,
      payload: false,
    })
  }
}

// Vaihda verkkokauppatunnuksen salasana kun se on unohtunut, ja nollaa nollauslinkki tiedot
export const actionChangePasswordWithCode =
  (resetCode, password, password2) => async (dispatch) => {
    if (password !== password2) {
      return dispatch(actionAlert('Uudet salasanat eivät täsmää.', 'danger'))
    }
    if (password.length < 10) {
      return dispatch(actionAlert('Salasana on liian lyhyt.', 'danger'))
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const body = JSON.stringify({ resetCode, newPassword: password })

    try {
      const res = await axios.patch(`${proxy}/api/public/reset-password`, body, config)

      if (res.status === 200) {
        alert('Salasana vaihdettu onnistuneesti.')
        dispatch({ type: CONSUME_PW_RESET })
        window.location = window.location.origin
      }
    } catch (err) {
      const errors = err.response.data.errors

      if (errors) {
        errors.forEach((error) => alert(error.msg))
      }
    }
  }

// Headerin tokenin avulla pyydetään käyttäjän tiedot tietokannasta ja tuodaan user object Redux storeen
export const actionLoadUser = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    const res = await axios.get(`${proxy}/api/private/auth`, config)

    dispatch({
      type: LOAD_USER,
      payload: res.data,
    })
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    })
  }
}

// Headerin tokenin avulla pyydetään käyttäjän tiedot tietokannasta ja tuodaan user object Redux storeen
export const actionReloadUser = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    const res = await axios.get(`${proxy}/api/private/auth`, config)

    dispatch({
      type: RELOAD_USER,
      payload: res.data,
    })
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    })
  }
}

// Sisäänkirjataan käyttäjä syötetyn sähköpostin ja salasanan avulla
// Jos tietokannasta löytyy osuma, antaa rajapinta vastaukseksi tokenin, joka tallennetaan Redux storeen ja headeriin
export const actionLogin =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch({ type: LOGIN_SUBMIT })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const body = JSON.stringify({ email, password })

    try {
      const res = await axios.post(`${proxy}/api/public/login`, body, config)

      if (res.status === 200) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        })

        setAuthToken(res.data.token)
        dispatch(actionLoadUser())
      }
    } catch (err) {
      const errors = err.response.data.errors

      if (errors) {
        errors.forEach((error) => dispatch(actionAlert(error.msg, 'danger')))
      }

      dispatch({
        type: LOGIN_FAIL,
      })
    }
  }

// Vaihda verkkokauppatunnuksen salasana Oma tili -sivulla
export const actionChangePassword =
  ({ oldpassword, newpassword, newpassword2 }) =>
  async (dispatch) => {
    if (newpassword !== newpassword2) {
      return dispatch(actionAlert('Uudet salasanat eivät täsmää.', 'danger'))
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const body = JSON.stringify({ oldpassword, newpassword })

    try {
      const res = await axios.post(`${proxy}/api/private/change-password`, body, config)

      if (res.status === 200) {
        dispatch({
          type: CHANGE_PASSWORD,
        })

        dispatch(actionAlert(res.data.msg, 'desperado'))
      }
    } catch (err) {
      const errors = err.response.data.errors

      if (errors) {
        errors.forEach((error) => dispatch(actionAlert(error.msg, 'danger')))
      }
    }
  }

// Hae viimeksi katsotut tuotteet
export const actionRecentsGet = (array) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const body = JSON.stringify({ array: array })

  try {
    const res = await axios.post(`${proxy}/api/public/get-data-for-buttons`, body, config)

    if (res.status === 200) {
      function sortFunc(a, b) {
        return array.indexOf(a.tuoteKoodi) - array.indexOf(b.tuoteKoodi)
      }

      res.data.sort(sortFunc)

      dispatch({
        type: RECENTS_GET,
        payload: { recents: res.data, prevRecentsQuery: array },
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: RECENTS_ERROR,
    })
  }
}

// Hae käyttäjän suosikit
export const actionFavoritesGet = (array) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const body = JSON.stringify({ array: array })

  try {
    const res = await axios.post(`${proxy}/api/public/get-data-for-buttons`, body, config)

    if (res.status === 200) {
      dispatch({
        type: FAVORITES_GET,
        payload: finalizeProducts(res.data),
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: FAVORITES_ERROR,
    })
  }
}

// Lisää käyttäjän tietoihin yksi tuote suosikiksi
// Jos ei kirjautunut, selaimen välimuistiin
export const actionFavoritesAdd = (code, isAuthenticated) => async (dispatch) => {
  if (isAuthenticated) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const body = JSON.stringify({ product: code })

    try {
      const res = await axios.post(`${proxy}/api/private/add-favorite`, body, config)

      if (res.status === 200) {
        dispatch(actionAlert(`Tuote ${code} lisätty suosikkeihin.`, 'desperado'))

        dispatch({
          type: FAVORITES_ADD,
          payload: res.data,
        })
      }
    } catch (err) {
      console.error(err)

      dispatch({
        type: FAVORITES_ERROR,
      })
    }
  } else {
    const current = localStorage.getItem('dc_shop_favorites')
    if (current) {
      const parsedCurrent = JSON.parse(current)
      parsedCurrent.push(code)
      localStorage.setItem('dc_shop_favorites', JSON.stringify(parsedCurrent))
      dispatch({
        type: FAVORITES_ADD,
        payload: parsedCurrent,
      })
      dispatch(actionAlert(`Tuote ${code} lisätty suosikkeihin.`, 'desperado'))
    } else {
      const data = [code]
      localStorage.setItem('dc_shop_favorites', JSON.stringify(data))
      dispatch({
        type: FAVORITES_ADD,
        payload: data,
      })
      dispatch(actionAlert(`Tuote ${code} lisätty suosikkeihin.`, 'desperado'))
    }
  }
}

// Poistaa kirjautuneen käyttäjän tiedoista yhden tuotteen suosikeista
export const actionFavoritesRemove = (code, isAuthenticated) => async (dispatch) => {
  if (isAuthenticated) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const body = JSON.stringify({ product: code })

    try {
      const res = await axios.post(`${proxy}/api/private/remove-favorite`, body, config)

      if (res.status === 200) {
        dispatch(actionAlert(`Tuote ${code} poistettu suosikeista.`, 'desperado'))

        dispatch({
          type: FAVORITES_REMOVE,
          payload: res.data,
        })
      }
    } catch (err) {
      console.error(err)

      dispatch({
        type: FAVORITES_ERROR,
      })
    }
  } else {
    const current = localStorage.getItem('dc_shop_favorites')
    if (current) {
      const parsedCurrent = JSON.parse(current)
      const filteredCurrent = parsedCurrent.filter((el) => el !== code)
      localStorage.setItem('dc_shop_favorites', JSON.stringify(filteredCurrent))
      dispatch({
        type: FAVORITES_REMOVE,
        payload: filteredCurrent,
      })
      dispatch(actionAlert(`Tuote ${code} poistettu suosikeista.`, 'desperado'))
    }
  }
}

// Lisää käyttäjän tietoihin yksi osoite osoiteluetteloon
export const actionAddressAdd = (newAddress) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const body = JSON.stringify({ newAddress })

  try {
    const res = await axios.post(`${proxy}/api/private/add-address`, body, config)

    if (res.status === 200) {
      dispatch(actionAlert(`Osoite lisätty osoiteluetteloon.`, 'desperado'))

      dispatch({
        type: ADDRESSLIST_ADD,
        payload: res.data,
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: ADDRESSLIST_ERROR,
    })
  }
}

// Poista yksi osoite käyttäjän osoiteluettelosta
export const actionAddressRemove = (addressToRemove) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const body = JSON.stringify({ addressToRemove })

  try {
    const res = await axios.post(`${proxy}/api/private/remove-address`, body, config)

    if (res.status === 200) {
      dispatch(actionAlert(`Osoite poistettu osoiteluettelosta.`, 'desperado'))

      dispatch({
        type: ADDRESSLIST_REMOVE,
        payload: res.data,
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: ADDRESSLIST_ERROR,
    })
  }
}

export const actionResetAddressDone = () => (dispatch) => {
  dispatch({ type: ADDRESSLIST_RESET })
}

// Kirjaa käyttäjä ulos, tyhjennä token headereistä ja nollaa user objekti
export const actionLogout = () => (dispatch) => {
  dispatch({ type: LOGOUT })
  dispatch({ type: 'CART_RESET' })
}

export const actionResetPasswordState = () => (dispatch) => {
  dispatch({ type: RESET_PW_STATE })
}

export const actionResetUserReloadState = () => (dispatch) => {
  dispatch({ type: RESET_USER_RELOAD })
}
