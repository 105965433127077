import {
  ORDER_SEND,
  ORDER_CONFIRM_AVAILABILITY,
  ORDER_RESET_CONFIRM_AVAILABILITY,
  ORDER_ADD,
  ORDER_GET_ONE,
  ORDER_GET_ALL,
  ORDER_SELECT,
  ORDER_RESET,
  ORDER_COPY_DATA,
  ORDER_COPY_RESET,
  ORDER_ERROR,
  ORDER_RESET_ERROR,
} from '../actions/types'

const initialState = {
  all: undefined,
  selected: undefined,
  sendSuccess: false,
  addSuccess: false,
  copySuccess: false,
  copyProducts: [],
  confirmAvailability: false,
  checkout: undefined,
  error: false
}

export default function reducerOrder(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case ORDER_SEND:
      return {
        ...state,
        sendSuccess: true,
        confirmAvailability: false,
      }

    case ORDER_CONFIRM_AVAILABILITY:
      return {
        ...state,
        confirmAvailability: true,
        checkout: payload,
      }

    case ORDER_RESET_CONFIRM_AVAILABILITY:
      return {
        ...state,
        confirmAvailability: false,
        checkout: undefined,
      }

    case ORDER_ADD:
      return {
        ...state,
        addSuccess: true,
        confirmAvailability: false,
      }

    case ORDER_GET_ALL:
      return {
        ...state,
        all: payload,
        sendSuccess: false,
        addSuccess: false,
        confirmAvailability: false,
      }

    case ORDER_GET_ONE:
    case ORDER_SELECT:
      return {
        ...state,
        selected: payload,
        sendSuccess: false,
        addSuccess: false,
        confirmAvailability: false,
      }

    case ORDER_COPY_DATA:
      return {
        ...state,
        copySuccess: true,
        copyProducts: payload,
        confirmAvailability: false,
      }

    case ORDER_COPY_RESET:
      return {
        ...state,
        copySuccess: false,
        copyProducts: [],
        confirmAvailability: false,
      }

    case ORDER_RESET:
      return initialState

    case ORDER_ERROR:
      return {
        ...state,
        error: true
      }

    case ORDER_RESET_ERROR:
      return {
        ...state,
        error: false
      }

    default:
      return state
  }
}
