import {
  CAMPAIGN_GET_ALL,
  CAMPAIGN_GET_PRODUCTS,
  CAMPAIGN_CURRENT_RESET,
  CAMPAIGN_ERROR,
} from '../actions/types'

const initialState = {
  all: [],
  allLoadingDone: false,
  products: [],
  productsLoadingDone: false,
}

export default function reducerCampaign(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case CAMPAIGN_GET_ALL:
      return {
        ...state,
        all: payload,
        allLoadingDone: true,
      }

    case CAMPAIGN_GET_PRODUCTS:
      return {
        ...state,
        products: payload,
        productsLoadingDone: true,
      }

    case CAMPAIGN_CURRENT_RESET:
      return {
        ...state,
        products: [],
        productsLoadingDone: false,
      }

    case CAMPAIGN_ERROR:
      return initialState

    default:
      return state
  }
}
