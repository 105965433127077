import { combineReducers } from 'redux'
import alert from './reducer.alert'
import article from './reducer.article'
import auth from './reducer.auth'
import campaign from './reducer.campaign'
import cart from './reducer.cart'
import contact from './reducer.contact'
import order from './reducer.order'
import product from './reducer.product'
import utils from './reducer.utils'

export default combineReducers({
  alert,
  article,
  auth,
  campaign,
  cart,
  contact,
  order,
  product,
  utils,
})
