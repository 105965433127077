// React & Routing
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'
import { actionFavoritesGet, actionFavoritesRemove } from '../../redux/actions/action.auth'

// Styling
import './Favorites.scss'
import { createItemButtons } from '../../components/ItemButton/ItemButton'
import emptyBox from './../../images/emptyBox.svg'

const Favorites = ({
  user,
  favorites,
  favoritesData,
  isAuthenticated,
  actionFavoritesGet,
  actionFavoritesRemove,
}) => {
  const [favoritesButtons, setFavoritesButtons] = useState(null)

  const emptyFavorites = () => {
    setFavoritesButtons(
      <div clasName='emptyFavorites'>
        <img src={emptyBox} alt='Tyhjä' width='80' height='80' />
        <p>Et ole vielä lisännyt suosikkeja.</p>
      </div>
    )
  }

  // 1. Haetaan suosikkilistan perusteella data painikkeita varten
  useEffect(() => {
    if (isAuthenticated) {
      if (user.favorites) {
        actionFavoritesGet(user.favorites)
      }
    } else {
      const storageFavorites = localStorage.getItem('dc_shop_favorites')
      if (storageFavorites) {
        actionFavoritesGet(JSON.parse(storageFavorites))
      }
    }
    // eslint-disable-next-line
  }, [user, isAuthenticated])

  // 2. Luodaan datasta painikkeet
  useEffect(() => {
    if (favoritesData && favoritesData.length) {
      const buttons = createItemButtons(
        favoritesData,
        isAuthenticated ? favorites : JSON.parse(localStorage.getItem('dc_shop_favorites')),
        true,
        null,
        actionFavoritesRemove,
        isAuthenticated
      )
      setFavoritesButtons(buttons)
    } else {
      emptyFavorites()
    }
    // eslint-disable-next-line
  }, [favoritesData])

  return (
    <div className='Favorites'>
      <Helmet>
        <title>Suosikit | dc-collection</title>
      </Helmet>
      <h1>
        <strong>Suosikit</strong>
      </h1>
      <div className={favoritesData && favoritesData.length ? 'favorites' : 'emptyFavorites'}>
        {favoritesButtons}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  favorites: state.auth.user.favorites,
  favoritesData: state.auth.favorites,
  isAuthenticated: state.auth.isAuthenticated,
})

const reduxActions = {
  actionFavoritesGet,
  actionFavoritesRemove,
}

export default connect(mapStateToProps, reduxActions)(Favorites)
