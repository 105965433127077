import axios from 'axios'

const setAuthToken = (token) => {
  if (token) {
    // token http kutsujen headereihin ja selaimen paikalliseen muistiin
    axios.defaults.headers.common['dc-shop-auth-token'] = token
    localStorage.setItem('shop-token', token)
  } else {
    // poistetaan token headereista ja paikallisesta muistista
    delete axios.defaults.headers.common['dc-shop-auth-token']
    localStorage.removeItem('shop-token')
  }
}

export default setAuthToken
