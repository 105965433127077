import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import './CookieAlert.scss'

const CookieAlert = (props) => {
  const acceptCookies = () => {
    localStorage.setItem('cookies-accepted', true)
    props.setHideCookieAlert(true)
  }

  return (
    <div className='CookieAlert'>
      <p>
        Sivusto käyttää evästeitä, jotta voisi palvella sinua paremmin. Hyväksyt evästeiden käytön
        jatkamalla sivuston käyttöä. <Link to='/data-protection'>Lue lisää evästeistä.</Link>
      </p>
      <Button variant='primary' onClick={acceptCookies}>
        OK
      </Button>
    </div>
  )
}

export default CookieAlert
