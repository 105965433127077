// Määrittää tuotteen isOutlet ja startingPrice arvot.
const finalizeProducts = (data) => {
  let duplicates = []
  const setOutlet = (prod) => {
    if (prod.outlet === 'all') {
      return (prod.isOutlet = true)
    } else if (prod.outlet === 'some') {
      duplicates.push(prod)
      return (prod.isOutlet = false)
    } else {
      return (prod.isOutlet = false)
    }
  }
  data.forEach(setOutlet)
  data = data.map((prod) => ({
    ...prod,
    variaatiot: !prod.isOutlet
      ? prod.variaatiot.filter((el) => !el.tagit.outlet)
      : prod.variaatiot.filter((el) => el.tagit.outlet),
  }))
  if (duplicates.length > 0) {
    duplicates = duplicates.map((prod) => ({
      ...prod,
      isOutlet: true,
      variaatiot: prod.variaatiot.filter((el) => el.tagit.outlet),
    }))
  }
  data = data.concat(duplicates)
  data = data.filter((el) => el.variaatiot.length > 0)
  data = data.map((el) => ({
    ...el,
    startingPrice: Math.min(...el.variaatiot.map((el) => el.ovh)),
  }))
  return data
}

export default finalizeProducts
