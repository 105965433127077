import { FETCH_ARTICLE, RESET_ARTICLE } from '../actions/types'

const initialState = {
  current: '',
}

export default function reducerArticle(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case FETCH_ARTICLE:
      return {
        ...state,
        current: payload,
      }

    case RESET_ARTICLE:
      return initialState

    default:
      return state
  }
}
