import React, { useEffect, useRef } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { Button } from 'react-bootstrap'

const Form3 = (props) => {
  const { submitForm, success } = props
  const formikRef = useRef()

  useEffect(() => {
    if (success) {
      formikRef.current.resetForm()
    }
  }, [success])

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Nimesi on liian lyhyt, min 2 merkkiä.')
      .max(100, 'Nimesi on liian pitkä, max 100 merkkiä.')
      .required('Tämä kenttä on pakollinen.'),
    company: Yup.string()
      .min(2, 'Yrityksesi nimi on liian lyhyt, min 2 merkkiä.')
      .max(100, 'Yrityksesi nimi on liian pitkä, max 100 merkkiä.'),
    address: Yup.string()
      .min(5, 'Osoite on liian lyhyt, min 5 merkkiä.')
      .max(50, 'Osoite on liian pitkä, max 50 merkkiä.'),
    zip: Yup.string()
      .min(5, 'Postinumero on liian lyhyt, oltava 5 merkkiä.')
      .max(5, 'Postinumero on liian pitkä, oltava 5 merkkiä.')
      .matches(/^[0-9]*$/, 'Vain numerot ovat sallittuja.'),
    city: Yup.string()
      .min(2, 'Postitoimipaikka on liian lyhyt, min 5 merkkiä.')
      .max(50, 'Postitoimipaikka on liian pitkä, max 50 merkkiä.')
      .required('Tämä kenttä on pakollinen.'),
    phone: Yup.string()
      .min(6, 'Puhelinnumero on liian lyhyt, oltava 6-13 merkkiä.')
      .max(13, 'Puhelinnumero on liian pitkä, oltava 6-13 merkkiä.')
      .matches(/^[+]?[0-9]*$/, 'Vain numerot ja plus-merkki (+) ovat sallittuja.'),
    email: Yup.string()
      .email('Tarkista sähköpostiosoitteen muoto.')
      .required('Tämä kenttä on pakollinen.'),
    retailer: Yup.string()
      .min(2, 'Jäleenmyyjä on liian lyhyt, min 2 merkkiä.')
      .max(50, 'Jäleenmyyjä on liian pitkä, max 50 merkkiä.'),
  })

  return (
    <div className='contactForm'>
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: '',
          company: '',
          address: '',
          zip: '',
          city: '',
          phone: '',
          email: '',
          retailer: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          submitForm(values)
        }}>
        {({ errors, touched }) => (
          <Form>
            <p style={{ margin: '30px auto 0 auto', maxWidth: '500px' }}>
              Haluatko ostaa tuotteitamme? Ota meihin yhteyttä alla olevalla lomakkeella ja etsimme
              sinua lähellä olevan jälleenmyyjän. Muistathan ilmoittaa postitoimipaikkasi.
            </p>

            <label htmlFor='name'>Nimi *</label>
            <Field id='name' name='name' placeholder='...' />
            {errors.name && touched.name ? <p className='validation-error'>{errors.name}</p> : null}

            <label htmlFor='company'>Yritys</label>
            <Field id='company' name='company' placeholder='...' />
            {errors.company && touched.company ? (
              <p className='validation-error'>{errors.company}</p>
            ) : null}

            <label htmlFor='address'>Osoite</label>
            <Field id='address' name='address' placeholder='...' />
            {errors.address && touched.address ? (
              <p className='validation-error'>{errors.address}</p>
            ) : null}

            <label htmlFor='zip'>Postinumero</label>
            <Field id='zip' name='zip' placeholder='...' />
            {errors.zip && touched.zip ? <p className='validation-error'>{errors.zip}</p> : null}

            <label htmlFor='city'>Postitoimipaikka *</label>
            <Field id='city' name='city' placeholder='...' />
            {errors.city && touched.city ? <p className='validation-error'>{errors.city}</p> : null}

            <label htmlFor='phone'>Puhelinnumero</label>
            <Field id='phone' name='phone' placeholder='...' type='tel' />
            {errors.phone && touched.phone ? (
              <p className='validation-error'>{errors.phone}</p>
            ) : null}

            <label htmlFor='email'>Sähköpostiosoite *</label>
            <Field id='email' name='email' placeholder='esimerkki@esimerkki.fi' type='email' />
            {errors.email && touched.email ? (
              <p className='validation-error'>{errors.email}</p>
            ) : null}

            <p style={{ margin: '24px auto 16px auto' }}>
              Minulla on jo jälleenmyyjä. Kerro jälleenmyyjäsi nimi / minkä yrityksen kautta päädyit
              tuotesivuille ja välitämme viestisi hänelle.
            </p>

            <label htmlFor='retailer'>Jälleenmyyjä</label>
            <Field id='retailer' name='retailer' placeholder='...' />
            {errors.retailer && touched.retailer ? (
              <p className='validation-error'>{errors.retailer}</p>
            ) : null}

            <p>Tähdellä merkityt kentät ovat pakollisia.</p>

            <Button type='submit' variant='dark'>
              Lähetä
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Form3
