import axios from 'axios'
import { UTILS_GET, UTILS_RESET, UTILS_ERROR } from './types'
import proxy from '../../utils/proxy'

// Hae kaikki data utils:sta
export const actionUtilsGet = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    const res = await axios.get(`${proxy}/api/public/get-utils`, config)

    if (res.status === 200) {
      dispatch({
        type: UTILS_GET,
        payload: res.data,
      })
    }
  } catch (err) {
    console.error(err)

    dispatch({
      type: UTILS_ERROR,
    })
  }
}

export const actionUtilsReset = () => async (dispatch) => {
  dispatch({
    type: UTILS_RESET,
  })
}
