import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

// Styling
import './NotFound.scss'
import warning from './../../images/warning.svg'

const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='notFoundContainer'>
      <h4>Sivua ei löytynyt.</h4>
      <img src={warning} alt='Varoitus' width='80' height='80' style={{ margin: '32px auto' }} />
      <Link to='/'>
        <p>Palaa etusivulle</p>
      </Link>
    </div>
  )
}

export default NotFound
