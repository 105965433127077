// React & Routing
import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// Redux
import { connect } from 'react-redux'

// Bootstrap
import { Button, Row } from 'react-bootstrap'

// Styling
import './Received.scss'

// Utils
import proxy from '../../utils/proxy'

const Received = () => {
  return (
    <div className='Received'>
      <Helmet>
        <title>Tilaus vastaanotettu | dc-collection</title>
      </Helmet>
      <h4>
        <strong>Tilaus vastaanotettu!</strong>
      </h4>
      <img src={`${proxy}/images/icons/check-circle-regular.svg`} alt='' id='receivedCheckIcon' />
      <p>
        Kiitos tilauksestasi. Oma tili -sivulta voit seurata tilauksesi etenemistä ja selata
        tilauksesi muita tietoja.
      </p>
      <Row>
        <Link to='/account'>
          <Button variant='dark'>Oma tili</Button>
        </Link>
        <Link to='/'>
          <Button variant='dark'>Etusivu</Button>
        </Link>
      </Row>
      <hr />
      <h5>Kysyttävää?</h5>
      <p>Autamme mielellämme verkkokaupamme asiointiin liittyvissä asioissa.</p>
      <Row id='contactUsRow'>
        <Link to='/contact-us'>
          <Button variant='outline-primary'>Ota yhteyttä</Button>
        </Link>
      </Row>
    </div>
  )
}

export default connect()(Received)
