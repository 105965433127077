import { fabric } from 'fabric'

const imageQualityScale = 4; // 24px fontti = 1, 96px fontti = 4 jne.

    export const TextCurved = fabric.util.createClass(fabric.Object, {
        type: 'text-curved',
        lockScalingX: true,
        lockScalingY: true,
        diameter: 250,
        kerning: 0,
        text: '',
        flipped: false,
        fill: '#000',
        fontFamily: 'Times New Roman',
        fontSize: 96, // in px
        fontWeight: 'normal',
        fontStyle: '', // "normal", "italic" or "oblique".
        cacheProperties: fabric.Object.prototype.cacheProperties.concat('diameter', 'kerning', 'flipped', 'fill', 'fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'stroke', 'strokeWidth'),
        stroke: null,
        strokeWidth: 0,
        hasControls: true,
    
        initialize: function(text, options) {
            options || (options = {});
            this.text = text;
    
            this.callSuper('initialize', options);
            this.set('lockUniScaling', true);

            this.setControlsVisibility({
                mt: false,
                mb: false,
                ml: false,
                mr: false,
                bl: false,
                br: false,
                tl: false,
                tr: false,
                mtr: true,
            });

            // Set diameter to text width initially
            this.diameter = options.diameter || 1600;
    
            // Draw curved text here initially too, while we need to know the width and height.
            var canvas = this.getCircularText();
            this._trimCanvas(canvas);
            this.set('width', canvas.width / imageQualityScale);
            this.set('height', canvas.height / imageQualityScale);
        },
    
        _getFontDeclaration: function()
        {
            return [
                // node-canvas needs "weight style", while browsers need "style weight"
                (fabric.isLikelyNode ? this.fontWeight : this.fontStyle),
                (fabric.isLikelyNode ? this.fontStyle : this.fontWeight),
                this.fontSize + 'px',
                (fabric.isLikelyNode ? ('"' + this.fontFamily + '"') : this.fontFamily)
            ].join(' ');
        },

        _trimCanvas: function(canvas)
        {
            var ctx = canvas.getContext('2d');
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';

            var w = canvas.width,
                h = canvas.height,
                pix = {x:[], y:[]}, n,
                imageData = ctx.getImageData(0,0,w,h),
                fn = function(a,b) { return a-b },
                padding = 5;

            for (var y = 0; y < h; y++) {
                for (var x = 0; x < w; x++) {
                    if (imageData.data[((y * w + x) * 4)+3] > 0) {
                        pix.x.push(x);
                        pix.y.push(y);
                    }
                }
            }
            pix.x.sort(fn);
            pix.y.sort(fn);
            n = pix.x.length-1;

            // Vähennä padding alusta ja lisää se loppuun
            var xStart = Math.max(0, pix.x[0] - padding);
            var yStart = Math.max(0, pix.y[0] - padding);
            w = pix.x[n] - xStart + padding;
            h = pix.y[n] - yStart + padding;

            var cut = ctx.getImageData(xStart, yStart, w, h);

            canvas.width = w;
            canvas.height = h;
            ctx.putImageData(cut, 0, 0);
        },
    
        // Source: http://jsfiddle.net/rbdszxjv/
        getCircularText: function()
        {
            var text = this.text,
                flipped = this.flipped,
                kerning = this.kerning,
                fill = this.fill,
                inwardFacing = true,
                startAngle = 0,
                canvas = fabric.util.createCanvasElement(),
                ctx = canvas.getContext('2d'),
                cw, // character-width
                x, // iterator
                clockwise = -1; // draw clockwise for aligned right. Else Anticlockwise

            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';

            function calculateDiameter(value) {
                var range = 3000;
                var a = range / 2;
                var b = a + 100;
                var newValue = (value - b > 0) ? a - (value - b) : -a - (value - b);
                return newValue === -1500 ? 1500 : newValue;
            }
            var diameter = calculateDiameter(this.diameter);

            if (diameter >= 0 && diameter <= 20) {
                diameter = 20;
            } else if (diameter >= -20 && diameter <= 0) {
                diameter = -20;
            }

            if (diameter < 0) {
                flipped = true;
            } else {
                flipped = false;
            }

            if (flipped) {
                startAngle = 180;
                inwardFacing = false;
            }

            diameter = Math.abs(diameter);
    
            startAngle *= Math.PI / 180; // convert to radians
    
            // Calc heigt of text in selected font:
            var d = document.createElement('div');
            d.style.fontFamily = this.fontFamily;
            d.style.whiteSpace = 'nowrap';
            d.style.fontSize = this.fontSize + 'px';
            d.style.fontWeight = this.fontWeight;
            d.style.fontStyle = this.fontStyle;
            d.textContent = text;
            document.body.appendChild(d);
            var textHeight = d.offsetHeight;
            document.body.removeChild(d);
                
            canvas.width = canvas.height = diameter;
            ctx.font = this._getFontDeclaration();
    
            // Reverse letters for center inward.
            if (inwardFacing) { 
                text = text.split('').reverse().join('') 
            };
    
            // Setup letters and positioning
            ctx.translate(diameter / 2, diameter / 2); // Move to center
            startAngle += (Math.PI * !inwardFacing); // Rotate 180 if outward
            ctx.textBaseline = 'middle'; // Ensure we draw in exact center
            ctx.textAlign = 'center'; // Ensure we draw in exact center
    
            // rotate 50% of total angle for center alignment
            for (x = 0; x < text.length; x++) {
                cw = ctx.measureText(text[x]).width;
                startAngle += ((cw + (x === text.length-1 ? 0 : kerning)) / (diameter / 2 - textHeight)) / 2 * -clockwise;
            }
    
            // Phew... now rotate into final start position
            ctx.rotate(startAngle);
    
            // Now for the fun bit: draw, rotate, and repeat
            for (x = 0; x < text.length; x++) {
                cw = ctx.measureText(text[x]).width; // half letter
                // rotate half letter
                ctx.rotate((cw/2) / (diameter / 2 - textHeight) * clockwise);
                // draw the character at "top" or "bottom"
                // depending on inward or outward facing
    
                // Stroke
                if (this.stroke && this.strokeWidth) {
                    ctx.strokeStyle = this.stroke;
                    ctx.lineWidth = this.strokeWidth * 3 * imageQualityScale;
                    ctx.miterLimit = 2;
                    ctx.strokeText(text[x], 0, (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight / 2));
                }
    
                // Actual text
                ctx.fillStyle = fill;
                ctx.fillText(text[x], 0, (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight / 2));
    
                ctx.rotate((cw/2 + kerning) / (diameter / 2 - textHeight) * clockwise); // rotate half letter
            }
            return canvas;
        },
    
        _set: function(key, value) {
            switch(key) {
                case 'scaleX':
                    this.fontSize *= value;
                    this.diameter *= value;
                    this.width *= value;
                    this.scaleX = 1;
                    if (this.width < 1) { this.width = 1; }
                    break;
    
                case 'scaleY':
                    this.height *= value;
                    this.scaleY = 1;
                    if (this.height < 1) { this.height = 1; }
                    break;
    
                default:
                    this.callSuper('_set', key, value);
                    break;
            }
        },
    
        _render: function(ctx)
        {
            var canvas = this.getCircularText();
            this._trimCanvas(canvas);

            this.set('width', canvas.width / imageQualityScale);
            this.set('height', canvas.height / imageQualityScale);

            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';
            ctx.drawImage(canvas, -this.width / 2, -this.height / 2, this.width, this.height);

            this.setCoords();
        },
    
        toObject: function(propertiesToInclude) {
            return this.callSuper('toObject', ['text', 'diameter', 'kerning', 'flipped', 'fill', 'fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'stroke', 'strokeWidth', 'styles'].concat(propertiesToInclude));
        }
    });
